import React from "react";
import PropTypes from "prop-types";

import SimpleNavigation from "./simple-navigation";
import LogoButton from "../general-components/logo-button";
import LanguageSwitch from "../i18n/language-switch";
import LoginHeaderBlock from "@js/intern/components/header-block";

const Header = (props) => {
  return (
    <header id="site-header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-2">
            <LogoButton />
          </div>
          <SimpleNavigation showSearch={props.showSearch} />
          <LanguageSwitch />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  showSearch: PropTypes.bool,
};

export default Header;
