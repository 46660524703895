import React from "react";
import Toast from "./toast";
import { useToastStore } from "../store/use-toast";
import { defaultSettings } from "../settings";
import { AnimatePresence } from "framer-motion";

const ToastContainer = ({ settings }) => {
  settings = { ...defaultSettings, ...settings };

  const toasts = useToastStore();

  return (
    <div className="toast-container">
      <AnimatePresence>
        {toasts.map((toast) => (
          <Toast key={toast.id} toast={toast} duration={settings.duration} />
        ))}
      </AnimatePresence>
    </div>
  );
};

export default ToastContainer;