import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import useAuthentication from "../use-authentication";
import LogoutButton from "./logout-button";

const UserStatusBlock = () => {
  const { auth } = useAuthentication();
  const loggedIn = !!auth.userLoginData.logout_token;

  const expirationDate = moment.unix(auth?.userLoginData?.jwt?.exp);

  return (
    <div className="user-status-block">
      {loggedIn ? (
        <>
          <p>
            Sie sind angemeldet als {auth.currentUser?.name}.<br />
            Sie werden am {expirationDate.format("DD.MM.YY HH:mm")} ausgeloggt.
          </p>
          <nav>
            <ul>
              <li>
                <Link to="/intern/dashboard">Zum Dashboard</Link>
              </li>
              <li>
                <LogoutButton />
              </li>
            </ul>
          </nav>
        </>
      ) : (
        <nav>
          <ul>
            <li>
              <Link to="/intern/login">Login</Link>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default UserStatusBlock;
