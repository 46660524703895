import React from "react";
import { useSelector } from "react-redux";
import LogoutButton from "./logout-button";
import { Link } from "react-router-dom";

const LoginHeaderBlock = () => {
  const auth = useSelector((reduxStore) => reduxStore.auth);

  const loggedIn = !!auth.userLoginData.logout_token;

  if (loggedIn) {
    return (
      <div>
        <LogoutButton />
      </div>
    );
  } else {
    return (
      <div>
        <Link to="/intern/login">Login</Link>
      </div>
    );
  }
};

export default LoginHeaderBlock;
