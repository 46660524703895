const authenticationDefaultState = {
    userLoginData: {},
  },
  authenticationReducer = (state = authenticationDefaultState, action) => {
    switch (action.type) {
      case "LOGIN": {
        return Object.assign({}, state, {
          userLoginData: action.userLoginData,
        });
      }
      case "USER_DATA": {
        return Object.assign({}, state, {
          currentUser: action.userData,
        });
      }
      case "LOGOUT": {
        return Object.assign({}, state, {
          currentUser: action.userData,
          userLoginData: {},
        });
      }
      case "PASSWORD_RESET": {
        const newState = Object.assign({}, state.userLoginData, {
          password_reset_required: !action.passwordReset,
        });
        return Object.assign({}, state, { userLoginData: newState });
      }
      case "PASSWORD_RESET_TEMP": {
        const newState = Object.assign({}, state.userLoginData, {
          temp_pass: !action.passwordResetTemp,
        });
        return Object.assign({}, state, { userLoginData: newState });
      }
      default:
        return state;
    }
  },
  authenticationUserDataAction = (userData) => ({
    userData,
    type: "USER_DATA",
  }),
  authenticationLoginAction = (userLoginData) => ({
    userLoginData,
    type: "LOGIN",
  }),
  authenticationLogoutAction = () => ({
    type: "LOGOUT",
  }),
  authenticationPasswordResetAction = (passwordReset) => ({
    passwordReset,
    type: "PASSWORD_RESET",
  }),
  authenticationPasswordResetTempAction = (passwordResetTemp) => ({
    passwordResetTemp,
    type: "PASSWORD_RESET_TEMP",
  });

export {
  authenticationDefaultState,
  authenticationReducer,
  authenticationUserDataAction,
  authenticationLoginAction,
  authenticationLogoutAction,
  authenticationPasswordResetAction,
  authenticationPasswordResetTempAction,
};
