import toast from "@general-components/ui/toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  authenticationLoginAction,
  authenticationLogoutAction,
  authenticationPasswordResetTempAction,
} from "./redux-authentication";
import axios from "axios";
import inMemoryCache from "@js/cache";
import { restHostBackend } from "@js/config";

/**
 * Custom authentication hook.
 * This hook provides the authentication object as well as
 * login, logout, forgot password and reset password functions.
 */
const useAuthentication = () => {
  const auth = useSelector((reduxStore) => reduxStore.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * Login API Call.
   */
  const login = async (email, password) => {
    try {
      const response = await axios.post(
        `/api/v1/login`,
        {
          name: email,
          pass: password,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: false,
        }
      );
      toast({
        message:
          "Sie wurden erfolgreich eingeloggt. Sie werden automatisch weitergeleitet.",
        type: "success",
      });

      dispatch(authenticationLoginAction(response.data));
      return response;
    } catch (error) {
      console.error(error);
      toast({
        message: "Etwas ist schiefgegangen. Bitte versuchen Sie es erneut.",
        type: "error",
      });
      return error.response;
    }
  };

  /**
   * Logout API Call.
   */
  const logout = () => {
    axios
      .post(
        `/api/v1/logout`,
        {
          access_token: auth.userLoginData.access_token,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: false,
        }
      )
      .then(() => {
        dispatch(authenticationLogoutAction({}));
        inMemoryCache.reset();
        toast({
          message: "Sie wurden erfolgreich ausgeloggt.",
          type: "success",
        });
        navigate("/intern/login");
      })
      .catch((error) => {
        console.error(error.response);
        toast({
          message: "Etwas ist schiefgegangen.",
          type: "error",
        });
        dispatch(authenticationLogoutAction({}));
      });
  };

  /**
   * Forgot Password API Call.
   */
  const forgotPassword = async (email) => {
    try {
      const response = await axios.post(
        `${restHostBackend}/user/lost-password?_format=json`,
        {
          mail: email,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: false,
        }
      );
      console.log(response.data);
      toast({
        message:
          "Temporäres Passwort wurde erfolgreich an Ihre Email-Adresse versendet.",
        type: "success",
      });
      navigate("/intern/reset-password");
      return response;
    } catch (error) {
      toast({
        message: "Etwas ist schiefgegangen. Bitte versuchen Sie es erneut.",
        type: "error",
      });
      console.error(error.response);
      return error.response;
    }
  };

  /**
   * Reset Password API Call.
   */
  const resetPassword = async (email, passwordTmp, passwordNew) => {
    try {
      const response = await axios.post(
        `${restHostBackend}/user/lost-password-reset?_format=json`,
        {
          name: email,
          temp_pass: passwordTmp,
          new_pass: passwordNew,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: false,
        }
      );
      console.log(response.data);
      toast({
        message:
          "Das Passwort wurde erfolgreich gespeichert. Sie werden nun zum Login weitergeleitet.",
        type: "success",
      });
      dispatch(authenticationPasswordResetTempAction(true));
      navigate("/intern/login");
      return response;
    } catch (error) {
      toast({
        message: "Etwas ist schiefgegangen. Bitte versuchen Sie es erneut.",
        type: "error",
      });
      console.error(error.response);
      return error.response;
    }
  };

  return { auth, login, logout, forgotPassword, resetPassword };
};

export default useAuthentication;
