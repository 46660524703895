let toasts = [];
let listeners = [];

/**
 * @typedef {"success" | "info" | "warning" | "error"} ToastType
 */

/**
 * Add a toast to the store.
 * @param {Object} toast - The toast object
 * @param {string} toast.message - The message to display in the toast
 * @param {ToastType} toast.type - The type of toast to display 
 */
export const addToast = ({ message, type }) => {
  const id = Date.now();
  toasts = [...toasts, { id, message, type }];
  listeners.forEach((listener) => listener());
};

export const removeToast = (id) => {
  toasts = toasts.filter((toast) => toast.id !== id);
  listeners.forEach((listener) => listener());
};

export const subscribe = (listener) => {
  listeners.push(listener);
  return () => {
    listeners = listeners.filter((l) => l !== listener);
  };
};

export const getToasts = () => toasts;
