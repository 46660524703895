import React, { useCallback, useEffect } from "react";

// Router
import { Outlet, useLocation } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// i18n
import { IntlProvider } from "react-intl";
import messages_de from "./i18n/translations/de.json";
import messages_en from "./i18n/translations/en.json";

// Login
import {
  authenticationLoginAction,
  authenticationLogoutAction,
} from "./intern/redux-authentication";
import { tokenExpired } from "./intern/util";

// Page Components
import PageFooter from "@js/footer/page-footer";
import Header from "@js/header/header";
import ErrorBoundary from "./error-boundary";
import ToastContainer from "@general-components/ui/toast/components/toast-container";

export const defaultLocale = "de",
  defaultMessages = messages_de;

const messages = {
  und: defaultMessages,
  de: messages_de,
  en: messages_en,
};

/**
 * @todo 404.
 */
const AppLayout = () => {
  const location = useLocation();
  const currentLanguage = useSelector((state) => state.i18n.currentLanguage);
  const auth = useSelector((reduxStore) => reduxStore.auth);
  const dispatch = useDispatch();

  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  const generateMainClasses = useCallback((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  }, []);

  useEffect(() => {
    if (
      auth?.userLoginData?.access_token &&
      auth?.userLoginData?.jwt.exp < Math.round(new Date().getTime() / 1000)
    ) {
      dispatch(authenticationLogoutAction());
    }
  }, []);

  useEffect(() => {
    if (
      auth?.userLoginData?.access_token &&
      tokenExpired(
        auth?.userLoginData?.access_token,
        auth?.userLoginData?.jwt.exp
      )
    ) {
      dispatch(authenticationLogoutAction());
    }

    document.body.className += `${generateMainClasses(location.pathname)}`;
  }, [generateMainClasses, location.pathname]);

  useEffect(() => {
    // Scroll to top on route change
    if (!location.hash) {
      window.scrollTo(0, 0);
      document.body.className = `${generateMainClasses(location.pathname)}`;
    }
  }, [generateMainClasses, location]);

  return (
    <IntlProvider
      locale={currentLanguage !== "und" ? currentLanguage : defaultLocale}
      defaultLocale={defaultLocale}
      messages={messages[currentLanguage]}
    >
      <div className="main-content">
        <ErrorBoundary>
          <Header showSearch={false} />
        </ErrorBoundary>

        <main className="main-page-content">
          {/**
           * Router was replaced by createStaticRouter (React Router v6).
           * Routes are now defined in the routes.js file. The <Outlet> component is
           * used to render the matched child route.
           * @see https://reactrouter.com/en/main/guides/ssr
           */}
          <Outlet />
          <ToastContainer />
        </main>

        <ErrorBoundary>
          <PageFooter />
        </ErrorBoundary>
      </div>
    </IntlProvider>
  );
};

AppLayout.propTypes = {};

export default AppLayout;
