class Error {
  constructor({ message, status }) {
    this.message = message;
    this.status = status;

    return new Response(message, { status });
  }
}

class NotFound extends Error {
  constructor() {
    super({ message: "Not Found", status: 404 });
  }
}

class Forbidden extends Error {
  constructor() {
    super({ message: "Forbidden", status: 403 });
  }
}

class NotAuthorized extends Error {
  constructor() {
    super({ message: "Not Authorized", status: 401 });
  }
}

class NetworkError extends Error {
  constructor() {
    super({ message: "Network Error", status: 503 });
  }
}

export { Error, NotFound, Forbidden, NotAuthorized, NetworkError };
